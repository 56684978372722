.inputSection{
    width: 100%;
    gap: 0.5em
}

.input{
    width: 100%;
    padding: 1em;
    border: 1px solid rgb(218, 218, 218);
    outline: none;
    border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -ms-border-radius: 0.3em;
    -o-border-radius: 0.3em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.input:hover,
.input:focus{
    border: 1px solid rgba(163, 163, 163,1);
}

.input::placeholder{
    color: lightgray;
}

.inputLabel{
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: -0.5em;
    color: grey;
}

.inputWarning{
    font-size: 0.6em;
    color: rgb(163, 163, 163);
}
