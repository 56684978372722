.dropdownSection{
    width: auto;

}

.dropdownSection>select{
    padding: 0.5em 1em;
    background: transparent;
    border: 1px solid rgb(231, 231, 231);
    color: grey;
    border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -ms-border-radius: 0.8em;
    -o-border-radius: 0.8em;
}