.authenticationSection{
    width: 100%;
    height: 100vh;
    /* position: relative; */
    z-index: -99;
}

.authInputDiv{
    text-align: left;
}

.authHeading>h2{
    font-weight: 500;
}

.authBtndiv{
    padding-top: 1em;
}

.forgotText{
    margin-top: -1em;
    text-align: right;
}

.authChangeLink{
    text-align: center;
}

.authBtndiv{
    justify-content: center;
}

.authBtndiv button{
    
}

.authenticationSection>div{
    position: relative;
    padding: 2em;
    z-index: 9999;
}

.authBackground{
    position: absolute;
    inset: 0;
    z-index: -99;
    background: rgba(255, 255, 255, 0.4); /* Adjust the alpha value (0.5) for transparency */
    backdrop-filter: blur(10px); /* Adds a blur effect to the background */
    border-radius: 8px; /* Optional: Gives a rounded corner effect */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 10px){
    .authenticationSection>div{
        width: 100%;
        height: 100%;
    }

    .authenticationSection{
        max-height: 100vh;
    }
}

@media only screen and (min-width: 768px){
    .authenticationSection>div{
        width: 50%;
        height: auto;
    }
}

@media only screen and (min-width: 1200px){
    .authenticationSection>div{
        width: 40%;
    }
}

@media only screen and (min-width: 1400px){
    .authenticationSection>div{
        width: 30%;
    }
}