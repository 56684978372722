
.productSection{
    width: 100%;
    max-width: 1700px;
}

.pretProductHeaderBottom ul{
    list-style: none;
}

.pretProductFilterTxt p, .pretProductSortByTxt p{
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.pretProductFilterTxt{
    position: relative;
}

.pretProductDropMenu{
    position: absolute;
    background: var(--header-dark);
    /* width: 100%; */
    min-width: 10em;
    display: none;
}

.pretProductDropMenu li{
    width: 100%;
    height: 100%;
}

.pretProductDropMenu li a{
    /* padding: 0.5em; */
    width: 100%;
    height: 100%;
    padding: 1em !important;
    font-size: 1.2em;
    color: white;
}

.pretProductDropMenu a:hover{
    background: var(--txt-light);
    
}

.pretProductDropMenu a:hover {
    color: var(--header-dark);
}

.pretProductContent{
    padding: 2em 0em;
    flex-wrap: wrap;
}

.productImgDiv{
    position: relative;
    padding-top: 125%;
    background: rgba(240, 240, 240, 1);
    
}

.productAbs{
    position: absolute;
    inset: 0;
    /* overflow: hidden; */
}

.productAbs img{
    max-width: 100%;
    max-height: 100%;
}

.pretProductHeader{
    padding-top: 3em;
}

.pretProductHeading>p{
    font-size: 1.3em;
    letter-spacing: 0.1em;
    font-weight: 400;
}

.pretProductHeading>h2{
    font-size: 2.5em;
    font-weight: 400;
    margin: 0.5em 0em;
}



@media only screen and (min-width: 10px) {
    .pretProductContent>div{
        padding: 0em 2em;
        flex-wrap: wrap;
    }

    .productBtnDiv{
        padding: 5em 0em;
    }

    .pretProductHeader, .pretProductContent, .productBtnDiv{
        width: 100%;
        padding: 0 0em;
    }
    
    .pretProductContent>div{
        gap: 1.5em;
    }

    .productBtnDiv{
        padding: 5em 0em !important;
    }

    .pretProductHeader{
        padding: 0 2em;
    }

    .pretProductHeader{
        padding-top: 3em;
    }
}

@media only screen and (min-width: 768px) {
    .productSection{
        max-width: 350px;
    }
}


@media only screen and (min-width: 1000px) {
    .productSection{
        max-width: 300px;
    }
    .pretProductContent>div{
        padding: 0em 2em;
        flex-wrap: wrap;
    }

    .pretProductContent>div{
        gap: 1.5em;
    }

    .pretProductHeader{
        padding: 0 2em;
    }
}

@media only screen and (min-width: 1200px) {
    .productSection{
        max-width: 200px;
    }
    
    .pretProductContent>div{
        padding: 0em 0em;
        flex-wrap: wrap;
    }

    .productBtnDiv{
        padding: 5em 0em !important;
    }

    .pretProductHeader, .pretProductContent, .productBtnDiv{
        width: 80%;
        padding: 0 0em;

    }

    .pretProductContent>div{
        gap: 3em;
    }

    .pretProductHeader{
        padding: 0 0em;
    }
}


@media only screen and (min-width: 1400px) {
    .productSection{
        max-width: 300px;
    }
}