.bannerSection{
    max-width: 100%;
    /* height: 110vh;
    max-height: 800px; */
    
}

.bannerContainer{
    position: relative;
    overflow-y: hidden;
    display: flex;
    align-items: center;
}

.bannerImgDiv{
    position: absolute;
    inset: 0;
    z-index: -1;
}

.bannerImgDiv>img{
    max-width: 100%;
    max-height: 100%;
    /* object-fit: contain; */
}

.bannerText{
    padding: 3em 6em;
    margin-left: 9em;
    color: white;
    text-align: center;
    position: absolute;
    top: 25%;
    bottom: 25%;
}

.bannerText>h3{
    font-size: 6em;
    font-family: heading, sans-serif;
    letter-spacing: 0em;
    line-height: 1em;
    font-weight: 400;
    /* padding-bottom: 44px; */
}

.bannerText>p{
    font-size: 2em;
    /* font-family: 'Josefin Sans', sans-serif; */
    letter-spacing: 0.2em;
    line-height: 1.2em;
    padding-top: 44px;
    padding-bottom: 56px;
}

.bannerText>ul{
    gap: 2em;
}

.bannerText>ul a{
    letter-spacing: 0.1em;
    font-size: 1.5em;
}

@media only screen and (min-width: 10px){
    .bannerText{
        padding: 3em 1em;
        margin-left: 0em;
        justify-content: center;

    }

    .bannerContainer{
        /* height: auto; */
        /* padding-top: 15em; */
        padding-top: 108%;
    }

    .bannerText>h3{
        font-size: 1.5em;
    }

    .bannerText>p{
        font-size: 0.7em;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .bannerMenu{
        gap: 1em;
    }

    .bannerText>ul{
        gap: 1em;
        flex-direction: column;

    }

    .bannerText>ul a{
        letter-spacing: 0.1em;
        font-size: 1em;
    }

    .bannerMenu>p{
        display: none;
    }
}

@media only screen and (min-width: 350px){
    .bannerText{
        padding: 3em 1em;
    }

    .bannerText>h3{
        font-size: 1.8em;
    }

    .bannerText>p{
        font-size: 0.8em;
    }
}

@media only screen and (min-width: 480px){
    .bannerText>ul{
        flex-direction: row;
    }

    .bannerMenu>p{
        display: flex;
    }
}

@media only screen and (min-width: 600px){
    
    .bannerText>h3{
        font-size: 3em;
    }

    .bannerText>p{
        font-size: 1em;
        padding-top: 22px;
        padding-bottom: 27px;
    }
}

@media only screen and (min-width: 650px){
    .bannerContainer{
        padding-top: 54%;
    }
}

@media only screen and (min-width: 780px){
    .bannerText{
        padding: 3em 2em;
        top: 10%;
    }

    .bannerMenu{
        gap: 2em;
    }

    .bannerText>ul{
        gap: 2em;
    }

    .bannerText>ul a{
        letter-spacing: 0.1em;
        font-size: 1em;
    }

    .bannerContainer{
        /* max-height: 70vh; */
    }

    .bannerText>h3{
        font-size: 3em;
    }

    .bannerText>p{
        font-size: 1.3em;
        padding-top: 20px;
        padding-bottom: 30px;
    }
}

@media only screen and (min-width: 900px){
    .bannerText{
        padding: 3em 3em;
        margin-left: 4em;
        top: 8%;
        bottom: 0;
    }

    .bannerText>h3{
        font-size: 4em;
    }

    .bannerText>p{
        font-size: 1.8em;
        padding-top: 44px;
        padding-bottom: 56px;
    }
    
}

@media only screen and (min-width: 1000px){
    .bannerText{
        padding: 3em 3em;
        margin-left: 4em;
        top: 15%;
        bottom: 0;
    }
    
}

@media only screen and (min-width: 1200px){
    .bannerText{
        padding: 3em 3em;
        margin-left: 4em;
        top: 15%;
        bottom: 0;
    }

    .bannerContainer{
        /* max-height: 110vh; */
        padding-top: 54%;
    }

    .bannerText>h3{
        font-size: 6em;
    }
    .bannerText>p{
        font-size: 2em;
        padding-top: 44px;
        padding-bottom: 56px;
    }
}

@media only screen and (min-width: 1320px){
    .bannerText{
        padding: 3em 5em;
    }
}

@media only screen and (min-width: 1400px){
    .bannerText{
        padding: 3em 5em;
        top: 25%;
        bottom: 25%;
        margin-left: 9em;
        
    }
}