.profileDetailUpdateSection{
    padding: 5em 2em;
    background: rgb(244, 244, 244);
}

.profileDetailUpdateSection hr{
    margin-bottom: 0;
    margin: 1em 0;
    opacity: 0.1 !important;
}


.profileDetailUpdateSection>div{
    background: white;
    padding: 2em;
    text-align: left;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.profileDetailHeading>h2{
    font-weight: 450;
    color: grey;
    font-size: 1.5em;
}

.profileDetailForm>div button{
    padding: 1em 2.5em;
    font-size: 1em;
}