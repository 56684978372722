/* =======================Top Section CSS================================== */

.topHeaderSection{
    background: var(--header-dark);
    padding: 1em;
}

.topHeaderContent>p{
    color: white;
    letter-spacing: 0.2em;
    font-size: 0.5em;
}

@media only screen and (min-width: 10px){
    .topHeaderContent>p{
        font-size: 0.7em;
        line-height: 1em;
    }
}

@media only screen and (min-width: 768px){
    .topHeaderContent>p{
        font-size: 0.8em;
    }
}



/* ===========================Menu Btn CSS======================================== */

/* =======================Mid Section CSS================================== */

.midHeaderSection{
    padding: 0.5em 1em;
    padding-bottom: 0em;
}

.midHeaderContent{
    padding: .5em 1em;
}

#menuBtn{
    cursor: pointer;
}

.midHeaderImageDiv{
    display: flex;
    gap: 2.5em;
    
}

.midHeaderImageDiv>a{
    font-size: 1.5em;
    color: var(--header-dark);
}

@media only screen and (min-width: 100px){
    .midHeaderContent{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
    }

    .midHeaderText>p{
        font-size: 0.7em;
        letter-spacing: 0.1em;
    }
    
    .midHeaderContainer{
        gap: 0em;
    }
}

@media only screen and (min-width: 600px){
    .midHeaderContainer{
        gap: 2.5em;
    }
}

@media only screen and (min-width: 800px){
    .midHeaderContent{
        flex-direction: row;
        justify-content: space-between;

    }

    

    .midHeaderText>p{
        font-size: 0.7em;
    }
}


/* =======================Bottom Section CSS================================== */


.bottomHeaderSection{
    gap: 1em;
    margin-top: 0em;
}

.bottomHeaderContent>h2{
    font-size: 3.5em;
    font-family: heading, serif;
    letter-spacing: 0.01em;
    font-weight: 400;
}

.bottomHeaderMenuDiv>ul{
    gap: 2em;
    justify-content: center;
    align-items: center;
}

.bottomHeaderMenuDiv>ul p{
    font-size: 1.5em;
    text-align: center;
}

.bottomHeaderMenuDiv>ul a{
    letter-spacing: 0.1em;
    font-size: 1.3em;
    font-weight: 400;
}

@media only screen and (min-width: 10px) {
    .bottomHeaderContent>h2{
        font-size: 1.5em;
        text-align: center;
    }

    .bottomHeaderMenuDiv>ul>div{
        gap: 0.9em;
    }
    .bottomHeaderMenuDiv>ul{
        gap: 0.9em;
    }

    .bottomHeaderMenuDiv>ul a{
        font-size: 1em;
    }

    .bottomHeaderMenuDiv>ul p{
        font-size: 1em;
        text-align: center;
    }
}

@media only screen and (min-width: 400px) {
    .bottomHeaderContent>h2{
        font-size: 2em;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    .bottomHeaderContent>h2{
        font-size: 3.5em;
        text-align: center;
    }
    .bottomHeaderMenuDiv>ul>div{
        gap: 2em;
    }
    .bottomHeaderMenuDiv>ul{
        gap: 2em;
    }

    .bottomHeaderMenuDiv>ul a{
        font-size: 1.2em;
    }

    .bottomHeaderMenuDiv>ul p{
        font-size: 1.5em;
        text-align: center;
    }
}



/* =========================================Toggle Menu Css================================================== */

.headerSection{
    position: relative;
}

.toggleMenuSection{
    position: absolute;
    left: 10%;
    top: 3em;
    background: var(--bg-light);
    min-width: 28em;
    z-index: 99999;
    overflow: hidden;
    transition: height 200ms linear 0;
    -webkit-transition: height 200ms linear 0;
    -moz-transition: height 200ms linear 0;
    -ms-transition: height 200ms linear 0;
    -o-transition: height 200ms linear 0;
}


@keyframes heightZeroToFull {
    0%{
        height: 0em;
    }
    100%{
        height: auto;
    }
}

@keyframes heightFullToZero {
    0%{
        height: auto;
    }
    100%{
        height: 0em;
    }
}

.toggleMenuContainer{
    padding: 2em;
}

.toggleMenuHeaderTxt{
    font-size: 0.7em !important;
}

.toggleMenuHeaderIcon{
    cursor: pointer;
    font-size: 1em;
}

.toggleMenuSection p,
.toggleMenuSection h3{
    text-align: left;
    font-size: 0.9em;

}

.toggleMenuSection h3{
    font-weight: 400;
    letter-spacing: 0.2em;
}

.toggleMenuContent{
    padding: 3em 0;
}

.toggleMenuContent hr{
    margin: 1em 0;
}

.toggleMenuContent div{
    gap: 0.2em;
}

.midHeaderImageDiv{
    position: relative;
}

.navbarCartCount{
    position: absolute;
    top: -0.3em;
    right: -0.3em;
}

.navbarCartCount p{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    width: 1.5em;
    height: 1.5em;
    background: var(--header-dark);
    font-size: 0.5em;
    color: white;

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media only screen and (min-width: 10px){
    .toggleMenuSection{
        left: 0;
        min-width: auto;
        
        width: 100%;
    }
}

@media only screen and (min-width: 768px){
    .toggleMenuSection{
        left: 11.50%;
        width: auto;
        min-width: 28em;

    }
}