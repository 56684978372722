
.btn{
    padding: 0.5em 2em;
    outline: none;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 500;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    /*box-shadow: 0 0 0.5em 0.1em rgba(0,0,0,0.2);
    */transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.btn-light:hover{
    background: rgba(253, 230, 217,0);
    color: white;
    border: 1px solid white;
    
}


.btn-light{
    border: 1px solid white;
    background: white;
}

.btn-dark{
    background: rgba(253, 230, 217,0);
    color: var(--header-dark);
    border: 2px solid var(--header-dark);
    
}

.btn-dark:hover{
    background: var(--header-dark);
    color: white;
    border: 2px solid var(--header-dark);
}

.btn-darkFill{
    background: rgb(53, 51, 51);
    color: white;
    border: 2px solid var(--header-dark);
    
}

.btn-darkFill:hover{
    background: var(--header-dark);
    color: white;
    border: 2px solid var(--header-dark);
}


.btn-sec{
    background: transparent;
    color: var(--sec-color);
    border: 2px solid var(--sec-color);
    
}

.btn-sec:hover{
    background: var(--sec-color);
    color: white;
    border: 2px solid var(--sec-color);
}



@media only screen and (min-width: 10px){
    .btn{
        padding: 0.9em 1.5em;
        font-size: 0.7em;
    }
}

@media only screen and (min-width: 500px){
    .btn{
        padding: 0.9em 1.5em;
        font-size: 0.9em;
    }
}

@media only screen and (min-width: 768px){
    .btn{
        padding: 0.8em 3em;
        font-size: 1.2em;
    }
}